// Assets
import volume1_2021 from '@/assets/img/reports/volumen1_2021.png'
import volume2_2021 from '@/assets/img/reports/volumen2_2021.png'
import volume3_2021 from '@/assets/img/reports/volumen3_2021.png'
import volume4_2021 from '@/assets/img/reports/volumen4_2021.png'
import volume1_2022 from '@/assets/img/reports/volumen1_2022.jpg'
import volume1_2022_2 from '@/assets/img/reports/volumen1_2022-2.png'
import volume2_2023 from '@/assets/img/reports/volumen2_2023.jpg'
import volume2_2023_2 from '@/assets/img/reports/volumen2_2023-2.png'
import revolucionInformacion from '@/assets/img/reports/revolucion_informacion.png'

export default [
  {
    title: 'reports2021',
    id: '2021',
    image: volume1_2021,
    reports: [
      { title: 'volume1_2021', image: volume1_2021 },
      { title: 'volume2_2021', image: volume2_2021 },
      { title: 'volume3_2021', image: volume3_2021 },
      { title: 'volume4_2021', image: volume4_2021 },
    ],
  },
  {
    title: 'reports2022',
    id: '2022-2023',
    image: volume1_2022,
    reports: [
      { title: 'volume1_2022_en', image: volume1_2022_2 },
      { title: 'volume1_2022', image: volume1_2022 },
      { title: 'volume2_2023_en', image: volume2_2023_2 },
      { title: 'volume2_2023', image: volume2_2023 }
    ],
  },
  {
    title: 'reports2023',
    id: '2023',
    image: revolucionInformacion,
    reports: [
      { title: 'revolucionInformacion', image: revolucionInformacion },
    ],
  },
]
